// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Page,
} from "tabler-react";
import Select from 'react-select';
import $ from 'jquery';


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SiteWrapper from "../SiteWrapper.admin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import botService from "../../services/bot";
import userService from "../../services/user";
import authActions from "../../redux/auth/action";
import Buttonloader from '../../common/Loader/Buttonloader';
import Loader from '../../common/Loader/loader'


const validationRules = [
  {
    field: 'client',
    validations: ['required'],
    name: 'Client'
  },
  {
    field: 'name',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Bot Name'
  },
  {
    field: 'templateId',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Template Id'
  },
  {
    field: 'userType',
    validations: ['required'],
    name: 'User Type'
  },
];
class BotEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        client: this.props.botEditedData.clientId,
        name: this.props.botEditedData.name,
        userType: this.props.botEditedData.userType,
        userTypeId: this.props.botEditedData.userTypeId,
        templateId: this.props.botEditedData.templateId === undefined ? '' : this.props.botEditedData.templateId,
      },
      selectedOptionUserType: { label: this.props.botEditedData.userType, value: this.props.botEditedData.userTypeId },
      botEditedData: this.props.botEditedData,
      isLoading: false,
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
      optionUserTypes: this.props.optionUserTypes,
      botEditedData: this.props.botEditedData,
      deleteFirstQ: this.props.botEditedData.questions.length > 1 ? true : false ,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    //this.handleChangeQuestionValue = this.handleChangeQuestionValue(bind);
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }


   handleChangeEditbot(){
  }


  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  deleteOption(event,$this){
    var ele = $this.parent().parent();
    $this.parent().remove();
    this.manageOptionSequence(ele);
  }

  manageOptionSequence(ele){
    $($(ele)).children().each(function( index,element ) {

      var text = $(element).find('.form-label').text();
      var count = index+1;
      $(element).find('.form-label').html('Option '+count+'<span class="form-required">*</span>');
    });
  }

  deleteQuestion(event){
    $('#'+event.target.id).parent('.questions').remove();
    $('#questions_'+event.target.dataset.quesid).remove();
    $('#delete_option_'+event.target.dataset.quesid).remove();
    // if($('#delete_option_'+event.target.dataset.quesid).hasClass('deleteParent')){
    //     $('#delete_option_'+event.target.dataset.quesid).parent().remove();
    // }
    // else{
      
    // }
    this.manageSequence();
  }

  //manage question sequesnce
  manageSequence(){
    var quesLength =  $('.questions').length;
    $($('.questions')).each(function( index ) {
      var ele = $('.questions')[index];
      var text = $(ele).find('.form-label').text();
      var count = index+1;
      $(ele).find('.form-label').html('Question '+count+'<span class="form-required">*</span>');
      if(quesLength === 1){
        $(ele).find('.delete_question').hide();
      }
      else{
        $(ele).find('.delete_question').show();
      }
    });

  }


  addNewOption(event,id){

    event.preventDefault();
    const optionLength = $('#questions_'+id).find('.form-group').length+1;
    const optionHtml = '<div class="form-group options" style="margin-left: 50px;"><label class="form-label">Option '+optionLength+'<span class="form-required">*</span></label><input name="name" class="form-control" type="text" placeholder="Option" value=""><i class="fa fa-trash deleteOption"></i></div>';
    $('#questions_'+id).append(optionHtml);
  }

  addNewQuestion(event, divId){
    this.setState({
      deleteFirstQ: true
    });
    event.preventDefault();
    const divCount = ($('.questions').length+1);
    const questionHtml = '<div class="form-group questions newaddedquestions"><label class="form-label">Question '+divCount+'<span class="form-required">*</span></label><textarea name="questions[]" class="form-control" placeholder="Question" style="margin-top: 0px; margin-bottom: 0px; height: 95px;"></textarea><i class="fa fa-trash delete_question" data-quesId="'+divCount+'" id="delete_question_'+divCount+'"></i></div><div id="questions_'+divCount+'" class="newaddedquestion"></div><div class="d-flex newaddedquestion" id="delete_option_'+divCount+'"><button class="btn btn-primary  addoptions" type="button" id="question_'+divCount+'">Add New Option</button></div>';
    $('#all_questions').append(questionHtml);
    //var selectQuestionDiv = document.getElementById('all_questions');
    //selectQuestionDiv.innerHTML += questionHtml;
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();

    if (this.validate()) {
      const queationValues =  this.validateQuestions();

      const _self = this;
      if(this.props.botEditedData.isPublished){

        if($('.newaddedquestions').find('textarea').length === 0){
          toast.error("Please add atleast one question");
          return false;
        }

        $('.newaddedquestions').find('textarea').each(function(index,ele){
          if($(ele).val().trim() == ''){
            toast.error("Please fill up all the questions and options");
            return false;
          }
        });

        let data = {
          Questions: queationValues,
          botId: _self.props.botEditedData._id
        }


        _self.setLoadingToggle(true);
        botService.addQuestions(data)
        .then(response => {
          
          toast.success("Bot Updated successfully.");
          setTimeout(() => {
             _self.props.history.push("/admin/bots/mapped/"+_self.props.botEditedData._id);
          }, 3000)
          _self.props.onCancelEditForm(true);
          _self.setLoadingToggle(true);
          return false;
        })
        .catch(error => {
          toast.error(error.response.data.message)
          _self.setLoadingToggle(false);
        });
          
      }
      
      else if(queationValues && queationValues.length){
          let data = {
          name: this.state.fields.name,
          userTypeId: this.state.selectedOptionUserType.value,
          userType: this.state.selectedOptionUserType.label,
          clientId: this.state.fields.client,
          Questions: queationValues,
          botId: this.props.botEditedData._id,
          templateId: this.state.fields.templateId,
        }
        
        this.setLoadingToggle(true);
        botService.updateBot(data)
        .then(response => {
          
          toast.success("Bot Updated successfully.");
          setTimeout(() => {
             this.props.history.push("/admin/bots/mapped/"+this.props.botEditedData._id);
          }, 3000)
          this.props.onCancelEditForm(true);
          this.setLoadingToggle(true);
        })
        .catch(error => {
          toast.error(error.response.data.message)
          this.setLoadingToggle(false);
        })
      }
      else{
        toast.error("Please fill up all the questions and options")
      }
    }
  }

  //validateQuestions
  validateQuestions(){
    const  isPublished = this.state.botEditedData.isPublished;
    var questions = [];
    var selector = isPublished ? 'newaddedquestions' : 'questions';
    $('.'+selector).each(function( index,ele ) {
      var $optionele = $(ele).next();
      let ques = $(ele).find('textarea').val();
      var options = [];
      //GET OPTION VALUE
      index++;
      var questionType = 2;
      var optionInput = true;
      $($optionele).find('div').each(function( optionindex,optionele ) {
        let optionques = $(optionele).find('input').val();
        if(optionques.replace(/\s+/, "")  == ''){
          optionInput = false;
        }
        if(optionques != ''){
          var optionsData = {
            "option": optionques.toString()
          }
          options.push(optionsData);
          questionType = 1;
        }
        
      });

      if(ques != '' && ques != undefined  && optionInput){

        let data = {
          "question": ques.toString(),
          "questionType": questionType
        }
        if(options.length > 0){
          data['options'] = options;
        }
        //check bot is published && isPublished
 
        if($(ele).find('input').is('[disabled]') && isPublished) { 
        }
      else{
          questions.push(data);
        }
      }
      else{
        questions = [];
        return false;
      }
      
      
    });
    return questions;
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Bot Edit";
    let _self = this;
    $(document).unbind().on('click', '.addoptions', function(event){
      event.preventDefault();
      var divId = $(this).attr('id');
      if(divId !== undefined){
        var splitDiv = divId.split('_');
        _self.addNewOption(event, splitDiv[1]);
      }
      
    });

    //delete question
    $(document).on('click', '.delete_question', function(event){
      _self.deleteQuestion(event);
    });

    //delete option
    $(document).on('click','.deleteOption', function(event){
      _self.deleteOption(event,$(this));
    });
  }

    //change user type
  handleChangeUserType = selectedOptionUserType =>{
    const { fields } = this.state;
    fields['userType'] = selectedOptionUserType;
    this.setState(
      { 
        selectedOptionUserType:  selectedOptionUserType,
        fields
      }
    );
  }

  //close edit form
  closeEditForm(e){
    this.props.onCancelEditForm();
  }

  handleChangeQuestionValue(questions,e,index){
    questions[index]['question'] = e.target.value;
    let botEditedData  = this.state.botEditedData;

    this.setState({
      botEditedData
    });
  }

  handleChangeOptionValue(questions,e,questionindex,optionIndex){
    // console.log(e.target.value);
    questions[questionindex]['options'][optionIndex]['option'] = e.target.value ? e.target.value : ' ';
    let botEditedData  = this.state.botEditedData;
    setTimeout( () => {
      this.setState({
        botEditedData
      });
    },100);
    
  }


  render() {

    const { message } = this.state;
    const { optionUserTypes } = this.props;
    const { selectedOptionUserType } = this.state;
    const { botEditedData } = this.state;
   
    const { userTypeId } = this.props.botEditedData.userTypeId
    const { userTypename } = this.props.botEditedData.userType
    
   
    return (
       <Grid.Row>
        <Card.Body className="col col-lg-8 mx-auto"> 
        <form onSubmit={this.handleSubmit}>  
         <div className="bot-details">
          <Form.FieldSet>
            <Form.Group
              isRequired
              label="Bot Name"
            >
              <Form.Input name="name"
                onChange={(e) => this.handleChange(e, 'name')}
                placeholder='Bot Name'
                value={this.state.fields.name} 
                disabled={botEditedData.isPublished}/>
              <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
            </Form.Group>
          <div className="form-group" disabled={this.props.defaultDisabled ? true : null}>
            <label className="form-label">Select User type<span className="form-required">*</span></label>
           <Select
              name={this.props.userType}
              value={this.props.userTypeId}
              onChange={this.handleChangeUserType}
              options={optionUserTypes}
              isDisabled={botEditedData.isPublished}
              defaultValue={{ label: this.props.botEditedData.userType, value: this.props.botEditedData.userTypeId }}

            />
           
             <label style={{ display: this.state.errors.userType ? 'block' : 'none' }} className="error">{this.state.errors.userType}</label>
            </div>

             <Form.Group
              isRequired
              label="Template Id"
            >
              <Form.Input name="templateId"
                onChange={(e) => this.handleChange(e, 'templateId')}
                placeholder='Template Id'
                value={this.state.fields.templateId} 
                disabled={botEditedData.isPublished}/>
              <label style={{ display: this.state.errors.templateId ? 'block' : 'none' }} className="error">{this.state.errors.templateId}</label>
            </Form.Group>
           
          </Form.FieldSet>
          </div>
          <div className="alert alert-primary test_align_center" 
                role="alert">Provide Questions</div>
          <div className="bot-ques">
           <Form.FieldSet>
           <div id="all_questions">
           { botEditedData.questions.length ?
                      botEditedData.questions.map((question, index) => (
            <div key={question._id}>
             <Form.Group
                  isRequired
                  label={ "Question"+ (index+1)} className="questions">
                  <Form.Textarea name="questions[]"
                    placeholder='Question'
                    value={question.question}
                    disabled={botEditedData.isPublished}
                    onChange={(e) => this.handleChangeQuestionValue(botEditedData.questions,e,index)}
                    /> 
                    {!botEditedData.isPublished  ? 
                       <i className="fa fa-trash delete_question deleteParent" 
                       style={{ display: this.state.deleteFirstQ ? 'block' : 'none' }}
                        data-quesid={(index+1)} 
                        id={"delete_question_"+(index+1)}></i>
                      :
                      null
                    }

                    
                   
                </Form.Group>

                
              <div id={ 'questions_'+(index+1) }>
                 { question.options.length &&  question.options[0]['option'] !== ''?
                        question.options.map((option, optionindex) => (


                    <div className="form-group options" 
                          
                          style={{ marginLeft: '50px' }}
                          key={option._id}>
                    <label className="form-label">
                      Option {optionindex+1}
                    <span className="form-required">*</span>
                    </label>
                    <input name="name" className="form-control" type="text" 
                            placeholder="Option" value={option.option} 
                            disabled={botEditedData.isPublished}
                            onChange={(e) => this.handleChangeOptionValue(botEditedData.questions,e,index,optionindex)}/>
                      {!botEditedData.isPublished ?

                        <i className="fa fa-trash deleteOption"></i>
                      : null
                    }
                        
                  </div>
                  
                ))
                  : null
                            
                } 
              </div>
              { !question.isPublished ? 
                  <div className="d-flex" id={'delete_option_'+(index+1)}>
                    <button 
                      className="btn btn-primary  addoptions" 
                      type="button"  id={ 'question_'+(index+1) }>
                       Add New Option {question.isPublished }
                    </button>
                  </div>
                : null
               }
              
            </div>
           
          ))
            : null
                      
          }  
           </div> 
            </Form.FieldSet>
        </div>
          <div className="card-footer">
            <div className="d-flex">
              <button className="btn btn-primary ml-auto" 
                type="button" 
                 onClick={(e) => this.addNewQuestion(e, 1)}>
                Add new Questions
              </button></div></div>
          {!this.state.isLoading ?
            <div className="btn_set">
              <Button className="col-lg-4 aligncentre btn btn-primary btn-block" type="submit" style={{ clear:'both' }}>
                Update
              </Button>
              <Button className="col-lg-4 aligncentre btn btn-secondary btn-block" type="button" onClick={(e) => this.closeEditForm(e)}>
                Cancel
              </Button>
            </div>
            :
            <Buttonloader loading={this.state.isLoading} 
            addNewClass="col-lg-4 aligncentre"></Buttonloader>
          }
       
        </form> 
      </Card.Body> 
     </Grid.Row>
    )
  }

}


const mapStateToProps = (state) => {
  return {

  }
}
const bindActions = (dispatch) => {
  return {
    updateToastState: (payload) => dispatch(authActions.updateToastState(payload))
  }
}

//export default BotEdit;
export default withRouter(connect(mapStateToProps, bindActions)(BotEdit));

