// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon

} from "tabler-react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import SiteWrapper from "../SiteWrapper.client.react";
import clientService from "../../services/client";
import { validate } from '../../validate';
import { toast, ToastContainer } from 'react-toastify';
import Buttonloader from '../../common/Loader/Buttonloader'
import 'react-toastify/dist/ReactToastify.css';
import authActions from "../../redux/auth/action";

const validationRules = [
  {
    field: 'name',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Client Name'
  },
  {
    field: 'companyName',
    validations: ['required', 'min:3', 'max:100'],
    name: 'Company Name'
  },
  {
    field: 'address',
    validations: ['required', 'min:3', 'max:100'],
    name: 'Address'
  },
  {
    field: 'contactNumber',
    validations: ['required', 'digit:10', 'numeric'],
    name: 'Contact Number'
  },
  {
    field: 'alternateContactNumber',
    validations: ['digit:10', 'numeric'],
    name: 'Alternate Contact Number'
  },
  {
    field: 'email',
    validations: ['required', 'email'],
    name: 'Email'
  },
  {
    field: 'alternateEmail',
    validations: ['email'],
    name: 'Alternate Email'
  },
];

class ClientProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
        companyName: '',
        address: '',
        contactNumber: '',
        alternateContactNumber: '',
        email: '',
        alternateEmail: '',
        countryCode: '91',
      },
      message: {
        style: 'success',
        text: ''
      },
      isLoading: false,
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);

  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    this.validate();
  }


  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Edit client"
    clientService.getClient(this.props.user._id)
      .then(res => {
        if (res.statusCode === 200) {
          const client = res.data,
            fields = {
              name: client.name,
              companyName: client.companyName,
              address: client.address,
              contactNumber: client.contactNumber,
              alternateContactNumber: client.alternateContactNumber,
              email: client.email,
              alternateEmail: client.alternateEmail,
              countryCode: '91',
            };
          this.setState({ fields });
        } else if (res.statusCode === 400) {
          // const { alert } = this.state;
          // alert.show = true;
          // alert.style = 'danger';
          // alert.text = res.error.message || 'Network error';
          // this.setState({ alert });
        }
      })
      .catch(err => {
        console.log('ERROR', err);
      })
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();

    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        name: this.state.fields.name,
        companyName: this.state.fields.companyName,
        address: this.state.fields.address,
        contactNumber: this.state.fields.contactNumber,
        email: this.state.fields.email,
        countryCode: '91',
      }
      if (this.state.fields.alternateContactNumber != '') {
        data['alternateContactNumber'] = this.state.fields.alternateContactNumber;
      }
      if (this.state.fields.alternateEmail != '') {
        data['alternateEmail'] = this.state.fields.alternateEmail;
      }

      clientService.update(data, this.props.user._id)
        .then(response => {
          toast.success("Profile updated successfully.")
          setTimeout(() => {
            this.props.history.push("/client/" + this.props.user._id + "/dashboard/1");
          }, 3000)
        })
        .catch(error => {
          this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
          this.setLoadingToggle(false);
        })

    }
  }

  render() {

    const { message } = this.state;

    return (
      <SiteWrapper>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>Profile</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={'alert alert-' + message.style} >
                  <p>{message.text}</p>
                  <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                </div>
                :
                null
              }
              <form onSubmit={this.handleSubmit}>
                <Form.FieldSet>
                  <Form.Group
                    isRequired
                    label="Client Name"
                  >
                    <Form.Input name="name"
                      onChange={(e) => this.handleChange(e, 'name')}
                      placeholder='Client Name'
                      value={this.state.fields.name} />
                    <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                  </Form.Group>
                  <Form.Group
                    isRequired
                    label="Company Name"
                  >
                    <Form.Input name="companyName"
                      onChange={(e) => this.handleChange(e, 'companyName')}
                      placeholder='Company Name'
                      value={this.state.fields.companyName} />
                    <label style={{ display: this.state.errors.companyName ? 'block' : 'none' }} className="error">{this.state.errors.companyName}</label>
                  </Form.Group>
                  <Form.Group
                    isRequired
                    label="Address"
                  >
                    <Form.Input name="address"
                      onChange={(e) => this.handleChange(e, 'address')}
                      placeholder='Address'
                      value={this.state.fields.address} />
                    <label style={{ display: this.state.errors.address ? 'block' : 'none' }} className="error">{this.state.errors.address}</label>
                  </Form.Group>
                  <Form.Group
                    className="mb-0"
                    isRequired
                    label="Contact Number"
                  >
                    <Form.Input name="contactNumber"
                      onChange={(e) => this.handleChange(e, 'contactNumber')}
                      placeholder='Contact Number'
                      value={this.state.fields.contactNumber} />
                    <label style={{ display: this.state.errors.contactNumber ? 'block' : 'none' }} className="error">{this.state.errors.contactNumber}</label>
                  </Form.Group>
                  <Form.Group
                    className="mb-0"
                    label="Alternate Contact Number"
                  >
                    <Form.Input name="alternateContactNumber"
                      onChange={(e) => this.handleChange(e, 'alternateContactNumber')}
                      placeholder='Alternate Contact Number'
                      value={this.state.fields.alternateContactNumber} />
                    <label style={{ display: this.state.errors.alternateContactNumber ? 'block' : 'none' }} className="error">{this.state.errors.alternateContactNumber}</label>
                  </Form.Group>
                  <Form.Group
                    className="mb-0"
                    isRequired
                    label="Email ID"
                  >
                    <Form.Input name="email"
                      onChange={(e) => this.handleChange(e, 'email')}
                      placeholder='Email Id'
                      value={this.state.fields.email} />
                    <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                  </Form.Group>
                  <Form.Group
                    className="mb-0"
                    label="Alternate Email ID"
                  >
                    <Form.Input name="alternateEmail"
                      onChange={(e) => this.handleChange(e, 'alternateEmail')}
                      placeholder='Alternate Email ID'
                      value={this.state.fields.alternateEmail} />
                    <label style={{ display: this.state.errors.alternateEmail ? 'block' : 'none' }} className="error">{this.state.errors.alternateEmail}</label>
                  </Form.Group>
                  
                </Form.FieldSet>

                {!this.state.isLoading ?
                  <Button color="primary btn-block" type="submit">Update</Button>
                  :
                  <Buttonloader loading={this.state.isLoading}></Buttonloader>
                }
              </form>
              <ToastContainer autoClose={5000} position={'bottom-right'}/>
            </Card.Body>
          </Card>
        </Grid.Col>
      </SiteWrapper >
    )
  }

}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientProfile));

