import React from "react";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import { Grid, Card, Table, Page, colors, Form } from "tabler-react";
import {
  getleadManualStatuses,
  updateManualStatus,
} from "../../services/client";

class UpdateStatusPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openUpdateStatusPopup,
      data: this.props.data,
      leadManualStatusArr: [],
      status: this.props.data.manual_status,
    };
    //this.openModal = this.props.openPopup;
    this.closeUpdateStatusModal = this.closeUpdateStatusModal.bind(this);
  }

  closeUpdateStatusModal() {
    this.props.onChange(false);
  }

  submitReply(e) {
    this.props.onChange(false);
  }
  componentDidMount() {
    this.getLeadStatuses();
  }

  async updateLead() {
    try {
      console.log("update", this.state.data._id, this.state.status);
      let res = await updateManualStatus(
        this.state.data._id,
        this.state.status
      );
      console.log(res);
      toast.success("Updated");
      this.closeUpdateStatusModal();
      // this.getChatUsers(this.props.match.params.page, this.state.search, 10)
    } catch (error) {
      console.error(error);
    }
  }

  async getLeadStatuses() {
    try {
      let res = await getleadManualStatuses();
      this.setState({ leadManualStatusArr: res.data });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    // console.log('admin-engage-score',this.props.openInfoPopup.data)
    const openUpdateStatusPopup = this.props.openUpdateStatusPopup.open;
    const replyOption =
      this.props.openUpdateStatusPopup.reply || this.state.reply;
    const message = this.props.openUpdateStatusPopup.data;
    console.log("admin-engage-score", this.props);
    return (
      <div>
        <Popup
          open={openUpdateStatusPopup}
          onClose={this.closeUpdateStatusModal}
          closeOnDocumentClick
        >
          <div className="card">
            <div className="card-header">
              <div className="card-options">
                <a
                  className="card-options-remove cursorPointer"
                  data-toggle="card-remove"
                  onClick={() => {
                    this.closeUpdateStatusModal();
                  }}
                >
                  <i className="fe fe-x" />
                </a>
              </div>
            </div>
            <div className="card-body">
              {/* <div>
                {JSON.stringify(this.state.data, null, 2)}
              </div> */}
              <div>
                <h6>Update Status for Lead</h6>
              </div>
              <div>
                <select
                  onChange={(e) =>
                    this.setState({
                      status: e.target.value,
                    })
                  }
                  value={this.state.status}
                  className="form-control mt-3 mb-2"
                  style={{ margin: "unset" }}
                >
                  {/* <option value="" disabled /> */}
                  {this.state.leadManualStatusArr &&
                    !!this.state.leadManualStatusArr.length &&
                    this.state.leadManualStatusArr.map((el) => (
                      <option value={el}>{el}</option>
                    ))}
                </select>
              </div>
              <div>
                <button
                  className="btn btn-primary sub2"
                  onClick={() => {
                    this.updateLead();
                  }}
                >
                  {" "}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default UpdateStatusPopup;
