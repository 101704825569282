import http, { axiosBasicAuth } from '../http';
import axios from "axios";
const API_URL = process.env.REACT_APP_apiUrl;

async function login(credentials) {
  try {

    let response = await axiosBasicAuth.post(`${API_URL}admins/login`, credentials);
    return response.data;
  }
  catch (error) {
    return error.response;
  }
}

async function logout() {
  try {

    let response = await http.post(`${API_URL}admins/logout`);
    return response.data;
  }
  catch (error) {
    return error.response;
  }
}

async function forgotPassword(data) {
  const url = `${API_URL}admins/password/forgot`;
  let response = await axiosBasicAuth.post(url, data);
  return response.data;
}

async function getUserTypes(page, search = false, limit = 10, clientId = false) {
  let params = 'page=' + page + '&limit=' + limit;
  if (search) {
    params += '&search=' + search
  }
  if (clientId) {
    params += '&clientId=' + clientId
  }
  const url = `${API_URL}userstype/userTypeList?` + params;
  let response = await http.get(url);
  return response.data;
}

async function createUserType(fields) {
  const url = `${API_URL}userstype/addUserType`;
  let response = await http.post(url, fields);
  return response.data;
}

async function updateUserType(fields) {
  const url = `${API_URL}userstype/update`;
  let response = await http.patch(url, fields);
  return response.data;
}

async function blockUnblock(id, data) {
  const url = `${API_URL}userstype/blockUnblock/` + id;
  let response = await http.patch(url, data);
  return response.data;
}

export default {
  login,
  logout,
  forgotPassword,
  getUserTypes,
  createUserType,
  blockUnblock,
  updateUserType
}
