import React from "react";
import Popup from "reactjs-popup";
import { Grid, Card, Table, Page, colors, Form } from "tabler-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class DownloadPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.openInfoPopup,
      startDate: new Date(),
      endDate: new Date(),
    };
    //this.openModal = this.props.openPopup;
    this.closeInfoModal = this.closeInfoModal.bind(this);
  }

  closeInfoModal() {
    this.props.onChange(false);
  }

  submitReply(e) {
    this.props.onChange(false);
  }

  handleChange(type, date) {
    this.setState({
      [type]: date,
    });
  }

  // exportData(){

  //   var url = process.env.REACT_APP_apiUrl+'admins/download-chats';

  //   var fromDate = new Date(this.state.startDate)
  //   fromDate.setHours(0, 0, 0, 0);
  //   var toDate = new Date(this.state.endDate)
  //   toDate.setHours(23, 59, 59, 0);
  //   var startDate =  fromDate.toISOString();
  //   var endDate =  toDate.toISOString()

  //   url = url+'?fromDate='+startDate+'&toDate='+endDate+'&adminId='+this.props.openInfoPopup.data;
  //   this.closeInfoModal()
  //   toast.success("Chat Downloaded Successfully.")
  //   window.open(url, '_blank');
  // }

  exportData() {
    var url = process.env.REACT_APP_apiUrl + "lead/download";

    var fromDate = new Date(this.state.startDate);
    // fromDate.setHours(0, 0, 0, 0);
    var toDate = new Date(this.state.endDate);
    // toDate.setHours(23, 59, 59, 0);
    var startDate = fromDate.toLocaleDateString("en-US");
    var endDate = toDate.toLocaleDateString("en-US");

    // url = url+'?fromDate='+startDate+'&toDate='+endDate+'&adminId='+this.props.openInfoPopup.data;
    url = url + "?fromDate=" + startDate + "&toDate=" + endDate;
    console.log("urlurlurl", url);
    this.closeInfoModal();
    toast.success("Downloaded Successfully.");
    window.open(url, "_blank");
  }

  render() {
    const openInfoPopup = this.props.openInfoPopup.open;
    const replyOption = this.props.openInfoPopup.reply || this.state.reply;
    return (
      <div>
        <Popup
          open={openInfoPopup}
          onClose={this.closeInfoModal}
          closeOnDocumentClick
        >
          <div className="card">
            <div className="card-header">
              <h2>Download Transcripts</h2>
              <div className="card-options">
                <a
                  className="card-options-remove cursorPointer"
                  data-toggle="card-remove"
                  onClick={() => {
                    this.closeInfoModal();
                  }}
                >
                  <i className="fe fe-x" />
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="form-fieldset row ana-fliter">
                <div className="col col-6 col-sm-4 col-lg-4">
                  <label className="form-label">From Date</label>

                  <DatePicker
                    className="form-control"
                    selected={this.state.startDate}
                    onChange={(e) => this.handleChange("startDate", e)}
                    maxDate={this.state.endDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>

                <div className="col col-6 col-sm-4 col-lg-4">
                  <label className="form-label">To Date</label>
                  <DatePicker
                    className="form-control"
                    selected={this.state.endDate}
                    onChange={(e) => this.handleChange("endDate", e)}
                    minDate={this.state.startDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
                <div className="col col-6 col-sm-4 col-lg-4">
                  <label className="form-label">&nbsp;</label>
                  <button
                    className="btn btn-primary btn-block aligncentre"
                    type="submit"
                    onClick={(e) => this.exportData()}
                  >
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
        <ToastContainer autoClose={5000} position={"bottom-right"} />
      </div>
    );
  }
}

export default DownloadPopup;
