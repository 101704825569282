// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Table,
  Icon,
  Page,
} from "tabler-react";
import Select from 'react-select';
import $ from 'jquery';

import Popup from "reactjs-popup";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SiteWrapper from "../SiteWrapper.admin.react";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import botService from "../../services/bot";
import userService from "../../services/user";
import authActions from "../../redux/auth/action";
import Buttonloader from '../../common/Loader/Buttonloader';
import Loader from '../../common/Loader/loader'
import BotEdit from '../bots/BotEdit'
import ViewButton from './view-button';
import ButtonEdit from './edit-button'
import SiteWrapperAdmin from "../SiteWrapper.admin.react";
import { changeLanguage } from '../../../src/language/index'


const validationRules = [
  {
    field: 'name',
    validations: ['required','min:1'],
    name: 'Category'
  },
  {
    field: 'text',
    validations: ['required', 'min:3'],
    name: 'Template Id'
  }
];

class ButtonCreate extends React.Component {

  constructor(props) {
    console.log('SiteWrapperAdmin')
    super(props);
    this.state = {
      fields: {
        name: '',
        text: '',
        hsmMessage: ''
      },
      modalStatus: false,
      isLoading: false,
      templates: [],
      deleteFirstO: false,
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
      addTemplateForm: false,
      editedTemplate: false,
      templateEditedData: {},
      openPopupViewTemplate: false,
      openPopupEditTemplate: false,
      selectedBtnId:''
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.openViewTemplatePopup = this.openViewTemplatePopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.openEditTemplatePopup = this.openEditTemplatePopup.bind(this);
    this.closeEditPopup = this.closeEditPopup.bind(this);
    this.editTemplate = this.editTemplate.bind(this);
    this.deleteButton=this.deleteButton.bind(this); 
  }

  openViewTemplatePopup(e,data){
    this.setState({
      openPopupViewTemplate: {
        open: true,
        data: data,
      }
    })
  }

  closePopup(data){
    this.setState({
       openPopupViewTemplate: {
        open: false,
        data: false
      }
    })
  }

  openEditTemplatePopup(e,data){
    this.setState({
      openPopupEditTemplate: {
        open: true,
        data: data,
      }
    })
  }

  closeEditPopup(data){
    this.setState({
       openPopupEditTemplate: {
        open: false,
        data: false
      }
    })
  }

  //call bots api
  getButtons(){
    

    this.setLoadingToggle(true);
     botService.getButtons()
      .then(res => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            templates: res.data.result,
          }));
          this.setLoadingToggle(false);
        }
      }).catch(error => {
        this.setLoadingToggle(false);
      });
  }


  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  //bot form toggle
  templateFormToggle(){
    this.setState({ addTemplateForm: this.state.addTemplateForm =! this.state.addTemplateForm })
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {
     
        
          let data = {
            name: this.state.fields.name,
          text: this.state.fields.text,
 
        }
       
        this.setLoadingToggle(true);
        botService.createButton(data)
        .then(response => {
          toast.success("Button created successfully.")
          this.getButtons();
          this.templateFormToggle();
          this.setState({
            fields: {
              name: '',
              text: '',
            }
          });
         
        })
        .catch(error => {
          toast.error(error)
          this.setLoadingToggle(false);
        })
      
    
    }
  }

  //validateQuestions
  validateQuestions(){
    var options = [];
    $('.template-option').each(function( index,ele ) {
      var $optionele = $(ele).next();
      let textclass = $(ele).find('.textclass').val();
      let optionvalue = $(ele).find('.optionvalue').val();
     
       let data = {
          "text": textclass.toString(),
          "value": optionvalue.toString(),
        }
        if(textclass == '' || optionvalue == ""){
          options = [];
          return false;
        }
        options.push(data);
    });
    return options;
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Manage Template";
    let _self = this;
    // $(document).unbind().on('click', '.addoptions,.addoptionsdynamic', function(event){
    //   event.preventDefault();
    //   var divId = $(this).attr('id');
    //   if(divId !== undefined){
    //     var splitDiv = divId.split('_');
    //     //$('#'+divId).remove();
    //     _self.addNewOption(event, splitDiv[1]);
    //   }
      
    // });

    //delete question
    $(document).on('click', '.delete_question', function(event){
      _self.deleteQuestion(event);
    });

    //delete option
    $(document).on('click','.delete_option', function(event){
      _self.deleteOption(event,$(this));
    });
    //call api
    this.getButtons();
  }


  deleteOption(event,$this){
    var ele = $('.all_options');
    let _self = this;
    $this.parent().parent().parent().remove();
    this.manageOptionSequence(ele);
    const optionLength = $('.template-option').length;
    if(optionLength <2){
      $('.delete_option').hide();
    }
     
  }

  manageOptionSequence(ele){
    $($(ele)).children().each(function( index,element ) {

      var text = $(element).find('.form-label').text();
      var count = index+1;
      $(element).find('.form-label').html('Option '+count+'<span class="form-required">*</span>');
    });
  }

  deleteQuestion(event){
    $('#'+event.target.id).parent('.questions').remove();
    $('#questions_'+event.target.dataset.quesid).remove();
    $('#delete_option_'+event.target.dataset.quesid).remove();
    this.manageSequence();
  }

  //manage question sequesnce
  manageSequence(){
    var quesLength =  $('.questions').length;
    $($('.questions')).each(function( index ) {
      var ele = $('.questions')[index];
      var text = $(ele).find('.form-label').text();
      var count = index+1;
      $(ele).find('.form-label').html('Question '+count+'<span class="form-required">*</span>');
      if(quesLength === 1){
        $(ele).find('.delete_question').hide();
      }
      else{
        $(ele).find('.delete_question').show();
      }
    });

  }


  addNewOption(event,id){

    this.setState({
      deleteFirstO: true
    });
    //<i class="fa fa-plus addoptionsdynamic" id="question_'+id+'"></i>

    event.preventDefault();
    const optionLength = $('.template-option').length+1;
    const optionHtml = '<div class="template-option_'+optionLength+' template-option"><fieldset class="form-fieldset"><div id="all_options"><div class="form-group options"><label class="form-label">Option '+optionLength+'<span class="form-required">*</span></label><input name="text[]" class="form-control textclass" type="text" placeholder="Text" value=""></div><div class="form-group options"><input name="optionvalue[]" class="form-control optionvalue" type="text" placeholder="Value" value=""></div><i class="fa fa-trash delete_option" data-optionid="'+optionLength+'" id="delete_option_'+optionLength+'" style="display: block;"></i></div></fieldset></div>';
    $('.all_options').append(optionHtml);
    if(optionLength >1){
      $('.delete_option').show();
    }
  }



  //toggle bot status
  statusToggle(bot){
    const toggleData = bot.status === 'active' ? 'block' : 'active'
    let data = {
      status: toggleData
    }
    this.setLoadingToggle(true);
    botService.blockUnblock(bot._id, data)
      .then(response => {
        toast.success("Bot " + toggleData + " successfully.")
        this.getButtons();
        this.setLoadingToggle(false);
      })
      .catch(error => {
        this.setLoadingToggle(false);
        toast.error(error.response.data.message)

      })
  }

  deleteButton(template){
    if(template === 'show_modal'){
      this.setState({
        modalStatus:true
      })
    }else if(template === 'close_modal'){
      this.setState({
        modalStatus:false
      })
    }else{
      botService.deleteButtons(this.state.selectedBtnId)
      .then(response => {
        toast.success("Button deleted successfully.")
        this.getButtons();
      
        this.setState({
          modalStatus:false
        })
      })
      .catch(error => {
        toast.error(error)
        this.setLoadingToggle(false);
      })
    
    }
    
  }
  //toggle bot status
  editTemplate(template){
    
    //this.closeAddForm();
    //remove new blank question
    //$('.newaddedquestion').remove();
    this.setState({
      addTemplateForm: false,
      editedTemplate: false,
    });

     this.setLoadingToggle(true);
    // botService.getBot(bot._id)
    //   .then(response => {
      let _self = this;
      setTimeout(function(){ 
        _self.setState({
          editedTemplate: true,
          addTemplateForm: false,
          templateEditedData: template
        });
         _self.setLoadingToggle(false);
      }, 500);
     

       //console.log(this.state.templateEditedData);
      //   this.setLoadingToggle(false);
      // })
      // .catch(error => {
      //   this.setLoadingToggle(false);
      //   toast.error(error.response.data.message)
      // })
  }

  //close edit form
  closeEditForm(data = false){

    this.setState({
      addTemplateForm: false,
      editedTemplate: false,
    });
    this.getButtons();
  }

  //close add form
  closeAddForm(){
    this.closeEditForm();
  }

  render() {

    const { message } = this.state;
    const { templates } = this.state;
    const { addTemplateForm } = this.state;
    const { editedTemplate } = this.state;
    const { templateEditedData } = this.state;

    // let openPopup = this.props.openPopup.open;
    return (

     
      <SiteWrapperAdmin>
        
        <Page.Content className="bots">
       

<div className="card card01a">
                <Card>
                <Card.Header>
                  <Card.Title>{changeLanguage(this.props.siteLanguage,'button')}</Card.Title>
                  { !this.state.editedTemplate ? 
                           <Button color="primary btn-block add-bot marginright" 
                             type="button" onClick={(e) => this.templateFormToggle()}>
                              {changeLanguage(this.props.siteLanguage,'add_button')}
                            </Button>
                          :
                          null
                       }
                  </Card.Header>       
                  <Table
                  cards={true}
                 // striped={true}
                  responsive={true}
                  className="table-vcenter table table-btn"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>{changeLanguage(this.props.siteLanguage,'sl_no')}</Table.ColHeader>
                      <Table.ColHeader >{changeLanguage(this.props.siteLanguage,'button_name')} </Table.ColHeader>
                      <Table.ColHeader>{changeLanguage(this.props.siteLanguage,'button_text')}</Table.ColHeader>
                      <Table.ColHeader>{changeLanguage(this.props.siteLanguage,'action')}</Table.ColHeader>
                      
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {templates.length ?
                      templates.map((template, index) => (
                        <Table.Row key={index}>
                          <Table.Col>{index + 1}</Table.Col>
                          {/* <Table.Col className="w-2">
                        <Avatar imageURL="../images/9.jpg" />
                      </Table.Col> */}
                          <Table.Col>{template.name}</Table.Col>
                          <Table.Col>{template.text}</Table.Col>
                          <Table.Col className="text-nowrap actions">
                            {/* <a  className="cursorPointer col-bl" 
                                onClick={(e) => this.openViewTemplatePopup(e,template)}>
                                    <i className="fe fe-eye"></i>
                            </a> */}
                            <a className="cursorPointer col-bl" onClick={(e) => this.editTemplate(template)}>
                                      <i className="fe fe-edit"></i>
                            </a>
                            <a className="cursorPointer col-bl" onClick={(e) => {this.setState({selectedBtnId:template._id});this.deleteButton('show_modal')}} >
                                      <i className="fe fe-trash"></i>
                            </a>
                           
                          </Table.Col>
                          <Popup className="modal2"
                              open={this.state.modalStatus}
                              onClose={this.closeModal}
                              closeOnDocumentClick
                            >
                              Do You want to delete?<br></br><br></br>
                              <button onClick={()=>this.deleteButton(template._id)} className="btn btn-primary"> Yes </button>
                              <button onClick={()=>this.deleteButton('close_modal')} className="btn btn-primary"> No </button>
                             
                          </Popup>
                        </Table.Row>
                        
                      ))
                      :
                      <Table.Row>
                        <Table.Col colSpan={7} >Sorry!  Buttons not found.</Table.Col>
                      </Table.Row>
                    }

                  </Table.Body>
                </Table>
                </Card>
                    </div>  
          <Grid.Row>
            <Grid.Col lg={12} className="mx-auto ">
            
              <Card >
              <form onSubmit={this.handleSubmit} id="template-create-form">
                               
              {!!addTemplateForm ?

                <Grid.Row>
                <Card.Body className="row">
                 
                    <div className="bot-details col col-lg-10 mx-auto">
                      <Form.FieldSet>
                          <Form.Group label=" " className="form3 form0">
                            <Form.Input name="name"
                              onChange={(e) => this.handleChange(e, 'name')}
                              placeholder={changeLanguage(this.props.siteLanguage,'button_name')}
                              value={this.state.fields.name} />

                            <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                          </Form.Group>
                         
                          <Form.Group label=" "  className="form0">
                            <Form.Input name="text"
                              onChange={(e) => this.handleChange(e, 'text')}
                              placeholder={changeLanguage(this.props.siteLanguage,'button_text')}
                              value={this.state.fields.text} />

                            <label style={{ display: this.state.errors.text ? 'block' : 'none' }} className="error">{this.state.errors.text}</label>
                          </Form.Group>

                           {/* <Form.Group
                            isRequired
                            label="Hsm Message"
                          >
                            <Form.Textarea name="hsmMessage" rows="10"
                              onChange={(e) => this.handleChange(e, 'hsmMessage')}
                              placeholder='Hsm Message'
                              value={this.state.fields.hsmMessage} />

                            <label style={{ display: this.state.errors.hsmMessage ? 'block' : 'none' }} className="error">{this.state.errors.hsmMessage}</label>
                          </Form.Group> */}
                          
                      </Form.FieldSet>
                    </div>

                    {/* <div className="all_options">
                     <div className="template-option_1 template-option">
                        <Form.FieldSet>
                           <div id="">
                           <Form.Group
                                isRequired
                                label="Option 1" className="options">
                                <Form.Input name="options[]"
                                  placeholder='Text'
                                  className="textclass"
                                  /> 
                              </Form.Group>
                              <Form.Group
                               
                                label="" className="options">
                                <Form.Input name="options[]"
                                  placeholder='Value'
                                  className="optionvalue"
                                  /> 
                              </Form.Group>
                              <i className="fa fa-trash delete_option"
                                  style={{ display: this.state.deleteFirstO ? 'block' : 'none' }} 
                                    data-optionid="1" id="delete_option_1"></i>
                              
                           </div>
                        </Form.FieldSet>
                     </div>
                     </div> */}
                     
                      {/* <div className="card-footer">
                      <div className="d-flex">
                        <button className="btn btn-primary ml-auto" 
                          type="button" 
                           onClick={(e) => this.addNewOption(e, 1)}>
                          Add new Option
                        </button>
                      </div>
                    </div> */}

                    {!this.state.isLoading ?
                      <div className="btn_set col-lg-2 btn-from">
                        
                        <Button className="aligncentre btn btn-secondary  " type="button" onClick={(e) => this.closeAddForm(e)}>
                        {changeLanguage(this.props.siteLanguage,'cancel')}
                        </Button>
                        <Button className="aligncentre btn btn-primary  " type="submit">{changeLanguage(this.props.siteLanguage,'save')}</Button>
                      </div>
                      :
                      <Buttonloader loading={this.state.isLoading} 
                      addNewClass="col-lg-4 aligncentre"></Buttonloader>
                    }
                 
                  
                </Card.Body> 
                </Grid.Row>
              : null
               }
                </form>
                {editedTemplate ?
                  <ButtonEdit 
                    templateEditedData = {this.state.templateEditedData}
                    onCancelEditForm={this.closeEditForm}
                  ></ButtonEdit>
                :
                null
              } 
               </Card>
            </Grid.Col>
          </Grid.Row>
          <Loader loading={this.state.isLoading}></Loader>
           <ToastContainer autoClose={5000} position={'bottom-right'}/>
        </Page.Content>
          <ViewButton 
              openPopup={this.state.openPopupViewTemplate} 
              onChange={this.closePopup}
              ></ViewButton>
          
             
      </SiteWrapperAdmin >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    siteLanguage:state.auth.siteLanguage
  }
}
const bindActions = (dispatch) => {
  return {
    updateToastState: (payload) => dispatch(authActions.updateToastState(payload))
  }
}
export default withRouter(connect(mapStateToProps, bindActions)(ButtonCreate));
//export default BotCreate;
