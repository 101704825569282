// @flow

// import React from "react";
// import ReactDOM from "react-dom";

// import "./index.css";
// import "./c3jscustom.css";

// import App from "./App.react";

// const rootElement = document.getElementById("root");

// if (rootElement) {
//   ReactDOM.render(<App />, rootElement);
// } else {
//   throw new Error("Could not find root element to mount to!");
// }


import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.react'
import './index.css';

import { Provider } from 'react-redux';
import { store } from '../src/redux/store/store';
// import { PersistGate } from 'redux-persist/es/integration/react';
//import registerServiceWorker from './registerServiceWorker';
//import Loader from './common/loader/Loader';

const VoiceocApp = () => (
  <Provider store={store}>

    <App />

  </Provider>
);

ReactDOM.render(<VoiceocApp />, document.getElementById('root'));
//registerServiceWorker();

if (module.hot) {
  module.hot.accept();
}

