import * as React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import {
//   Error404,
// } from "./pages";

// import LoginPage from "./auth/Login.react";
// import ForgotPassword from "./auth/forgot-password.react";
// import ResetPassword from "./auth/reset-password.react";
// import Dashboard from "./admin/Dashboard-react";
// import ClientList from "./admin/clients/Clients-list-react";
// import ClientCreate from "./admin/clients/Clients-create-react";
// import ClientEdit from "./admin/clients/Clients-edit-react";
// import ChangePassword from "./admin/settings/change-password.react";
// // client
// import DashboardClient from "./clients/Dashboard-client-react";
// import ClientChangePassword from "./clients/settings/change-password.react";
// import ClientProfile from "./clients/settings/profile.react";
// import UserImport from "./clients/users/import-react";


import "tabler-react/dist/Tabler.css";
import Routes from './routes/routes';


//const basename = process.env.NODE_ENV === 'production' ? '/voiceoc' : '/';
// const basename = '/voiceoc';


function App() {

  return (

    
      <Routes />
    
  );

  // return (
  //   <React.StrictMode>
  //     <Router basename={basename}>
  //       <Switch>
  //         <Route exact path="/" component={LoginPage} />
  //         <Route exact path="/forgot-password" component={ForgotPassword} />
  //         <Route exact path="/reset-password" component={ResetPassword} />
  //         <Route exact path="/login" component={LoginPage} />
  //         <Route exact path="/admin/dashboard" component={Dashboard} />
  //         <Route exact path="/admin/clients" component={ClientList} />
  //         <Route exact path="/admin/client/add" component={ClientCreate} />
  //         <Route exact path="/admin/client/edit/:id" component={ClientEdit} />
  //         <Route exact path="/admin/change-password" component={ChangePassword} />
  //         {/* route for client */}
  //         <Route exact path="/client/dashboard" component={DashboardClient} />
  //         <Route exact path="/client/change-password" component={ClientChangePassword} />
  //         <Route exact path="/client/profile" component={ClientProfile} />

  //         <Route exact path="/client/users/import" component={UserImport} />
  //         <Route component={Error404} />
  //       </Switch>
  //     </Router>
  //   </React.StrictMode>
  // );
}

export default App;
