import React from 'react';

const Buttonloader = (props) => {
    const { loading } = props;
    const { addNewClass } = props;
    return (
        <button 
        	className={
        		"btn btn-primary btn-block " +
        		(addNewClass ? addNewClass : "") }
        	type="button" style={{ display: !!loading ? 'block' : 'none' }}>
            Loading
            <i className="fa fa-circle-o-notch fa-spin"></i>
        </button>
    );
}

export default Buttonloader;
