// @flow

import React, { Fragment, useEffect, useMemo, useState } from "react";
import "c3/c3.css";
import C3Chart from "react-c3js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from "tabler-react";
import "tabler-react/dist/Tabler.css";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";
import "../../c3jscustom.css";
import { getNotificationChart } from "../../services/client";
const _ = require("lodash");
const moment = require("moment");

const axisLine = {
  x: {
    type: "category",
    tick: {
      beginAtZero: true,
      rotate: 75,
      multiline: false,
    },
    height: 130,
    unload: true,
  },
  y: {
    tick: {
      format: function(d) {
        return parseInt(d) == d ? d : null;
      },
    },
    unload: true,
  },
};

const buildData = (data, keyName) => {
  let result = [];
  _.forEach(data, (val, key) => {
    let totalCounts = val.reduce((acc, curr) => {
      return acc + curr.count;
    }, 0);
    result.push({ [keyName]: key, count: totalCounts });
  });
  return result;
};

const groupAndBuild = (data, dateMethod, groupKey) => {
  console.log("dval data", data);
  let groupedData = _.groupBy(data, (d) => {
    console.log("dval", d);
    return d.dateObj[dateMethod]();
  });
  return buildData(groupedData, groupKey);
};

export default function MessageTrafficAnalytic() {
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [sortValue, setSortValue] = useState();
  const [contextKey, setContextKey] = useState([]);
  const [contextArr, setContextArr] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [selectedCountryArr, setSelectedCountryArr] = useState([]);

  useEffect(
    () => {
      callServiceUnique(startDate, endDate);
    },
    [contextKey, selectedCountryArr]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("startDate",startDate, startDate.toISOString());
    console.log("endDate", endDate, startDate.toISOString());
    callServiceUnique(startDate, endDate);
  };

  const multiselectContext = (val) => {
    let checkData = contextKey.indexOf(val);
    if (checkData < 0) {
      setContextKey((prev) => [...prev, val]);
      setSortValue("byDay");
    } else {
      setContextKey((prev) => prev.filter((el) => el != val));
      setSortValue("byDay");
    }
  };

  useEffect(
    () => {
      consoleTRAFFIC(selectedCountryArr);
    },
    [selectedCountryArr]
  );

  const multiselectCountry = (val) => {
    console.log(val);
    let checkData = selectedCountryArr.indexOf(val);
    if (checkData < 0) {
      setSelectedCountryArr((prev) => [...prev, val]);
      setSortValue("byDay");
    } else {
      setSelectedCountryArr((prev) => prev.filter((el) => el != val));
      setSortValue("byDay");
    }
  };

  const consoleTRAFFIC = (...args) => {
    console.log("MESSAGETRAFFIC", ...args);
  };

  const callServiceUnique = async () => {
    if ((startDate != "" || startDate) && (endDate != "" || endDate)) {
      let res = await getNotificationChart(
        startDate.toISOString(),
        endDate.toISOString(),
        "notification",
        false
      );
      setNotificationData(res.data);
      console.log("res.data", res.data);
    }
  };

  const chartDataValue = useMemo(
    () => {
      // setting chart value
      let data = {
        bindto: "#graph2",
        x: "x",
        // unload:true,
        columns: [["x"], ["Responded"], ["Enqueued"], ["Sent"], ["Read"]],
        // colors: {
        //     'Responded': '#0A49B6',
        //     "Enqueued": '#fd9644',
        //     "Sent": '#fd9876',
        //     "Read": '#fd9232',
        // },
      };
      let xData = [];
      let respondedArr = [];
      let enqueuedArr = [];
      let sentArr = [];
      let readArr = [];

      if (notificationData && notificationData.length) {
        /**
         * Make Base Data
         */

        xData = notificationData
          .map((el) => el._id.date)
          .filter((el, i, arr) => i == arr.indexOf(el)); // unique check

        respondedArr = xData.map((el) => {
          let obj = notificationData.find(
            (x) =>
              x._id.date === el && x._id.status.toLowerCase() === "responded"
          );
          if (obj) return { _id: el, count: obj.count, dateObj: moment(el) };
          else return { _id: el, count: 0, dateObj: moment(el) };
        });

        enqueuedArr = xData.map((el) => {
          let obj = notificationData.find(
            (x) =>
              x._id.date === el && x._id.status.toLowerCase() === "enqueued"
          );
          if (obj) return { _id: el, count: obj.count, dateObj: moment(el) };
          else return { _id: el, count: 0, dateObj: moment(el) };
        });

        sentArr = xData.map((el) => {
          let obj = notificationData.find(
            (x) => x._id.date === el && x._id.status.toLowerCase() === "sent"
          );
          if (obj) return { _id: el, count: obj.count, dateObj: moment(el) };
          else return { _id: el, count: 0, dateObj: moment(el) };
        });

        readArr = xData.map((el) => {
          let obj = notificationData.find(
            (x) => x._id.date === el && x._id.status.toLowerCase() === "read"
          );
          if (obj) return { _id: el, count: obj.count, dateObj: moment(el) };
          else return { _id: el, count: 0, dateObj: moment(el) };
        });

        /**
         * group values by week month day
         */

        console.log("respondedArr", respondedArr);

        if (sortValue == "byWeek") {
          respondedArr = groupAndBuild(respondedArr, "week", "week");
          enqueuedArr = groupAndBuild(enqueuedArr, "week", "week");
          sentArr = groupAndBuild(sentArr, "week", "week");
          readArr = groupAndBuild(readArr, "week", "week");

          console.log("respondedArr week", respondedArr);

          xData = respondedArr.map((el) => {
            return "week-" + el.week;
          }); // xData = groupAndBuild(xData, 'week', 'week')
          console.log("respondedArr x week", xData);
        } else if (sortValue == "byMonth") {
          respondedArr = groupAndBuild(respondedArr, "month", "month");
          enqueuedArr = groupAndBuild(enqueuedArr, "month", "month");
          sentArr = groupAndBuild(sentArr, "month", "month");
          readArr = groupAndBuild(readArr, "month", "month");
          xData = respondedArr.map((el) => {
            return "month-" + el.month;
          }); // xData = groupAndBuild(xData, 'week', 'week')
        } else {
          // by day do nothing
        }

        /**
         * Mapping Data
         */

        respondedArr = respondedArr.map((el) => el.count);
        enqueuedArr = enqueuedArr.map((el) => el.count);
        sentArr = sentArr.map((el) => el.count);
        readArr = readArr.map((el) => el.count);
      } else {
      }

      if (!xData.length) xData = [null];
      if (!respondedArr.length) respondedArr = [null];
      if (!enqueuedArr.length) enqueuedArr = [null];
      if (!sentArr.length) sentArr = [null];
      if (!readArr.length) readArr = [null];

      console.log("data.columns[0] before", data.columns[0], xData);
      data.columns[0] = [...data.columns[0], ...xData];
      console.log("data.columns[0]", data.columns[0]);
      data.columns[1] = [...data.columns[1], ...respondedArr];
      data.columns[2] = [...data.columns[2], ...enqueuedArr];
      data.columns[3] = [...data.columns[3], ...sentArr];
      data.columns[4] = [...data.columns[4], ...readArr];

      console.log("finalData", data);

      return data;
    },
    [sortValue, notificationData]
  );

  return (
    <Fragment>
      <div className="box-al mar-40">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col col-6 col-sm-4 col-lg-5">
              <label className="form-label">From Date</label>
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
              />
            </div>

            <div className="col col-6 col-sm-4 col-lg-5">
              <label className="form-label">To Date</label>
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                maxDate={new Date()}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
              />
            </div>

            <div className="col col-6 col-sm-4 col-lg-2">
              <br />
              <Button color="primary   sub1" type="submit">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>

      {/* <div className="ml-4">

                <Form.Group label="Country" className="pull-right">
                    <Form.SelectGroup
                        canSelectMultiple
                        pills
                    >
                        {
                            countryArr.map(el => ({ key: el.country.replace('_', ' '), value: el.country })).map((val, index) => {
                                return (
                                    <Form.SelectGroupItem
                                        label={val.value}
                                        name={val.key}
                                        key={index}
                                        value="COUNTRY"
                                        onClick={(e) => multiselectCountry(val.key)}
                                    />
                                )
                            })
                        }


                    </Form.SelectGroup>
                </Form.Group>
            </div> */}

      <div className="col col-12 col-sm-12 col-lg-12">
        <div className="card card101">
          <div className="row">
            <div className=" pull-left col col-11 col-sm-12 col-lg-7 ">
              <h3 className="card-title">Message Traffic Analysis</h3>
            </div>
          </div>
          <div className="card-body p-3 text-center">
            <C3Chart
              data={chartDataValue}
              size={{
                height: 340,
              }}
              transition={{
                duration: 1000,
              }}
              axis={axisLine}
              padding={{
                bottom: 0,
                top: 0,
              }}
            />
          </div>
          <div>
            <div className="sortvalue drop-1">
              <select
                className="form-control custom-select auto1"
                onChange={(e) => setSortValue(e.target.value)}
                value={sortValue}
              >
                <option value="byDay">By Day</option>
                <option value="byWeek">By Week</option>
                <option value="byMonth">By Month</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
