import * as actionTypes from './action-type';

const authLogin = (payload) => {
  return {
    type: actionTypes.AUTH_LOGIN,
    payload
  }
}

const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

const authCheck = () => {
  return {
    type: actionTypes.AUTH_CHECK
  }
}


const updateUserData = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_DATA,
    payload
  }
}
const setReedirectUrl = (payload) => {
  return {
    type: actionTypes.SET_REDIRECT_URL,
    payload
  }
}

const setActiveBot = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_BOT,
    payload
  }
}

const updateToastState = (payload) => {
  return {
    type: actionTypes.SET_TOAST,
    payload
  }
}

const adminLoginAsClient = (payload) => {
  return {
    type: actionTypes.ADMIN_AS_CLIENT_LOGIN,
    payload
  }
}

const adminLogoutAsClient = (payload) => {
  return {
    type: actionTypes.ADMIN_AS_CLIENT_LOGOUT,
    payload
  }
}

const setLang = (payload) => {
  return {
    type: actionTypes.SET_LANG,
    payload
  }
}


export default {
  authLogin,
  authLogout,
  authCheck,
  updateUserData,
  setReedirectUrl,
  updateToastState,
  adminLoginAsClient,
  adminLogoutAsClient,
  setActiveBot,
  setLang
};