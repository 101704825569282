// @flow

import React from "react";
import {
  Button,
  Grid,
  Card,
  Table,
  Form,
  Page,
  Icon
} from "tabler-react";
import Select from 'react-select';
import { Link, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteWrapper from "./SiteWrapper.client.react";
import clientService from "../services/client";
import userService from "../services/user";
import authActions from "../redux/auth/action"
import { validate } from '../validate';
import Buttonloader from '../common/Loader/Buttonloader'
import CsvErrorMesage from './CsvErrorMessage';

const validationRules = [
  {
    field: 'name',
    validations: ['max:50'],
    name: 'Name'
  },
  {
    field: 'countryCode',
    validations: ['required'],
    name: 'Country Code'
  },
  {
    field: 'contactNumber',
    validations: ['required','numeric'],
    name: 'Contact Number'
  },
  {
    field: 'email',
    validations: ['email'],
    name: 'Email'
  },
  {
    field: 'userType',
    validations: ['required'],
    name: 'User Type'
  }
];

class AdminDashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      viewType: 'erp',
      currentPage: 1,
      users: [],
      pageLoading: true,
      currentPage: this.props.match.params.page,
      nextPage: false,
      prevoiusPage: false,
      selectedFile: '',
      csvTotalUsers: 0,
      erpTotalUsers: 0,
      manualTotalUsers: 0,
      userTypes: [],
      clientId: this.props.match.params.clientId,
      selectedOptionUserType: {},
      fileName: 'Select File',
      fields: {
        name: '',
        contactNumber: '',
        email: '',
        userType: '',
        countryCode: '91',
      },
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
      isLoading: false,
      openPopupCsv: false,
    }


    this.handleTabActive = this.handleTabActive.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateUser = this.handleCreateUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    if (this.props.user.role === 'admin') {
      this.props.history.push(`/client/${this.state.clientId}/analytics`);
    }
    document.title = "Voiceoc | Dashboard";
    this.pagination(false,this.state.currentPage,this.state.viewType);
    this.getUserTypes();
  }

  getUserTypes(){
   
    userService.getUserTypes(1,false,10000,this.state.clientId)
      .then(res => {
        if (res.statusCode === 200) {
          var firstObj = {};
          var typeValue = '';
          if(res.data.result.length){
            firstObj = {
              label: res.data.result[0].name, 
              value: res.data.result[0]._id
            }
            typeValue = res.data.result[0].name;
          }
          const { fields } = this.state;
          fields['userType'] = typeValue;
          this.setState(() => ({
            userTypes: res.data.result,
            selectedOptionUserType: firstObj,
            fields
          }));
        }
      }).catch(error => {
        
      })
  }

  handleTabActive = (tab) => {
    this.setState({ 
      viewType: tab,
      currentPage: 1
       });
   
    // if (tab === 'erp' || tab === 'manual') {
    //   this.state.users = [];
    // }
    // else {
      this.pagination(false,1,tab);
    //}
    
  }

  callService(page,tab = 'erp') {
    
    clientService.getUsers(page,tab)
      .then(res => {
        if (res.statusCode === 200) {
          this.setState(() => ({
            users: res.data.result,
            pageLoading: false,
            nextPage: res.data.next,
            prevoiusPage: this.state.currentPage > 1 ? true : false,
            csvTotalUsers: res.data.csvTotalUsers,
            erpTotalUsers: res.data.erpTotalUsers,
            manualTotalUsers: res.data.manualTotalUsers
          }));
        }
      }).catch(error => {
        this.setState(() => ({
          pageLoading: false
        }));
      })
  }

  pagination(e = false, page,tab) {
    this.callService(page,tab);
    this.props.history.push("/client/" + this.state.clientId + "/dashboard/" + page);
  }

  formReset() {
    document.getElementById("csvForm").reset();
  }

  onChangeFile = (event) => {
    
    if (event.target.files[0].type !== "text/csv"
      && event.target.files[0].type !== "application/vnd.ms-excel") {
      toast.error('Please select only csv file');
      this.formReset();
    }
    else {
      const data = new FormData();
      data.append('user_file', event.target.files[0]);
      data.append('clientId', this.props.match.params.clientId);
      this.setState({ selectedFile: data, fileName: event.target.files[0].name});
    }
  }

  //submit form
  handleSubmit = async event => {
    event.preventDefault();
    if (this.state.selectedFile === null || this.state.selectedFile == '') {
      toast.error('Please select csv file to upload');
      this.formReset();
      return false;
    }
    else {
      clientService.userImportByCsv(this.state.selectedFile)
        .then(response => {
          
          if(response.data.length){
            
            var arr = response.data.map(function(val){return ++val;});
            const errorRows = arr.join();
            this.openCsvPopup("Rows "+errorRows+" were not uploaded, please correct the data & try again. Thanks!")
          }
          else{
            toast.success("User imported successfully.")
          }
          this.setState(() => ({
            fileName: 'select file',
          }));
          this.pagination(false, 1,this.state.viewType);
          this.formReset();
        })
        .catch(error => {
         // console.log(error.response);
          //toast.error(error.response.data.message)
        });
    }

  }

  openCsvPopup(mesage){
    this.setState({
      openPopupCsv: {
        open: true,
        data: mesage,
      }
    })
  }

  closePopup(data){
    this.setState({
       openPopupCsv: {
        open: false,
        data: false
      }
    })
  }


    //submit user creation form
  handleCreateUser = async event => {
    event.preventDefault();
    if (this.validate()) {
      this.setLoadingToggle(true);
      let data = {
        contactNumber: this.state.fields.contactNumber,
        userType: this.state.fields.userType,
        countryCode: this.state.fields.countryCode,
        clientId: this.props.user.contactNumber
      }

      if(this.state.fields.name){
        data['name'] = this.state.fields.name
      }
      if(this.state.fields.email){
        data['email'] = this.state.fields.email
      }

      clientService.userCreate(data)
        .then(response => {
          toast.success("User created successfully.")
         this.setLoadingToggle(false);

         var firstObj = {};
         var typeValue = '';
         //reset select box
         if(this.state.userTypes.length){
            firstObj = {
              label: this.state.userTypes[0].name, 
              value: this.state.userTypes[0]._id
            }
            typeValue = this.state.userTypes[0].name;
          }

          const { fields } = this.state;
          fields['userType'] = typeValue;
          fields['countryCode'] = '91';

          this.setState(() => ({
            pageLoading: true,
            fields: {
              name: '',
              contactNumber: '',
              countryCode: '91',
              email: '',
              userType: typeValue,
            },
            selectedOptionUserType: firstObj,
          }));
           this.callService(1,this.state.viewType);
        })
        .catch(error => {
          //this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
          this.setLoadingToggle(false);
        })

    }
  }

  //handle notificatiion
  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

    //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }


  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  handleChangeUserType = selectedOptionUserType =>{
    const { fields } = this.state;
    fields['userType'] = selectedOptionUserType.label;
    this.setState(
      { 
        selectedOptionUserType:  selectedOptionUserType,
        fields
      }
    );
  }

  getPagination(){
    var recordEndTo = 0;
    var totalRecord = 0;
    var page = this.props.match.params.page;
    var startRecord = this.props.match.params.page == 1  ? this.props.match.params.page : ((10*(this.props.match.params.page-1))+1)
    var type = this.state.viewType
    if(type === 'erp'){
      totalRecord = this.state.erpTotalUsers;
    }
    if(type === 'manual'){
      totalRecord = this.state.manualTotalUsers;
    }
    if(type === 'csv'){
      totalRecord = this.state.csvTotalUsers;
    }
    if(this.state.users.length <1){
      return '0-0 of '+totalRecord;
    }
    else{
      var endRecord = (parseInt(startRecord)+parseInt(this.state.users.length)-1);
      return  startRecord+'-'+endRecord+' of '+totalRecord;
    }
    console.log(this.state.users.length);
    
    // return this.state.users.length +' of '+totalRecord;
  }

  render() {

    const { users } = this.state;
    const { pageLoading } = this.state;
    const { nextPage } = this.state;
    const { fileName } = this.state;
    const { message } = this.state;
    const { userTypes } = this.state;
    const optionUserTypes = userTypes.reduce((result, {name, _id,status}) => [...result, ...status =='active' ? [{ label: name, value: _id}] : []], []);
    const { selectedOptionUserType } =  this.state ;
    

    return (


      <SiteWrapper>
        <Page.Content>
          <Grid.Row>
            <Grid.Col lg={6}>
              <Card>
                <Card.Header className="alignselfcenter">
                  <div className="tabs">
                  <Card.Title className={this.state.viewType === 'erp' ? 'active_tab' : ''}>
                    <Link to="#" onClick={(e) => this.handleTabActive('erp')}>ERP({this.state.erpTotalUsers})</Link>
                  </Card.Title>
                  <Card.Title className={this.state.viewType === 'csv' ? 'active_tab' : ''}>
                    <Link to="#" onClick={(e) => this.handleTabActive('csv')}>CSV({this.state.csvTotalUsers})</Link>
                  </Card.Title>
                   <Card.Title className={this.state.viewType === 'manual' ? 'active_tab' : ''}>
                    <Link to="#" onClick={(e) => this.handleTabActive('manual')}>MANUAL({this.state.manualTotalUsers})</Link>
                  </Card.Title>
                  </div>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader>Sl. No.</Table.ColHeader>
                      <Table.ColHeader >Name</Table.ColHeader>
                      <Table.ColHeader>Phone No.</Table.ColHeader>
                      <Table.ColHeader>Status</Table.ColHeader>
                     
                    </Table.Row>
                  </Table.Header>
                  {!pageLoading ?
                    <Table.Body>
                      {users.length ?
                        users.map((user, index) => (
                          <Table.Row key={index}>
                            <Table.Col>{index + 1}</Table.Col>
                            <Table.Col>{user.name}</Table.Col>
                            <Table.Col className="text-nowrap">{user.contactNumber}</Table.Col>
                            <Table.Col className="text-nowrap">
                             
                              {user.status === 'read' ? <i className='fe fe-check-square'></i> 
                                  : user.status === 'sent' ? <i className='fe fe-check'></i> 
                                  : <i className='fe fe-check-circle'></i>
                              }
                            </Table.Col>
                          </Table.Row>
                        ))
                        :
                        <Table.Row>
                          <Table.Col colSpan={7} >Sorry!  users not found.</Table.Col>
                        </Table.Row>
                      }
                    </Table.Body>
                    :
                    <Table.Body>
                      <Table.Row>
                        <Table.Col className="pageloader" colSpan={7} >Loading.</Table.Col>
                      </Table.Row>
                    </Table.Body>
                  }
                </Table>
                {/* pagination */}
                <div className="form-group" style={{ float: 'right' }}>
                  <label className="form-label">

                  </label>
                  <div className="selectgroup align-to-right paginations">
                    <span>
                        
                        {this.getPagination()}
                    </span>
                   
                      <i 
                      className={
                        "fe " +
                        (this.props.match.params.page <= 1 ? "not-active " : "cursorPointer ") +
                        " fe-chevron-left"
                      }
                      onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) - 1),this.state.viewType)}
                      disabled={this.props.match.params.page <= 1}>
                      </i>
                      
                     
                   
                      <i  
                      className={
                        "fe " +
                        (!nextPage  ? "not-active " : "cursorPointer ") +
                        " fe-chevron-right"
                      }
                       onClick={(e) => this.pagination(e, (parseInt(this.props.match.params.page) + 1),this.state.viewType)}
                      disabled={!nextPage}></i>
                     
                  </div>
                </div>

              </Card>
            </Grid.Col>
            <Grid.Col lg={6}>
            <Card>
            <Card.Header>
              <Card.Title>User Create Form</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={'alert alert-' + message.style} >
                  <p>{message.text}</p>
                  <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                </div>
                :
                null
              }
              <form onSubmit={this.handleCreateUser}>
                <Form.FieldSet>
                  <Form.Group
                    
                    label="User Name"
                  >
                    <Form.Input name="name"
                      onChange={(e) => this.handleChange(e, 'name')}
                      placeholder='User Name'
                      value={this.state.fields.name} />
                    <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                  </Form.Group>

                  <Form.Group
                    
                    isRequired
                    label="Country Code"
                  >
                    <Form.Input name="countryCode"
                      onChange={(e) => this.handleChange(e, 'countryCode')}
                      placeholder='Country Code'
                      value={this.state.fields.countryCode} />
                    <label style={{ display: this.state.errors.countryCode ? 'block' : 'none' }} className="error">{this.state.errors.countryCode}</label>
                  </Form.Group>
                  
                  <Form.Group
                    
                    isRequired
                    label="Contact Number"
                  >
                    <Form.Input name="contactNumber"
                      onChange={(e) => this.handleChange(e, 'contactNumber')}
                      placeholder='Contact Number'
                      value={this.state.fields.contactNumber} />
                    <label style={{ display: this.state.errors.contactNumber ? 'block' : 'none' }} className="error">{this.state.errors.contactNumber}</label>
                  </Form.Group>
                  <Form.Group
                    
                    
                    label="Email ID"
                  >
                    <Form.Input name="email"
                      onChange={(e) => this.handleChange(e, 'email')}
                      placeholder='Email Id'
                      value={this.state.fields.email} />
                    <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                  </Form.Group>

                  <div className="form-group zindex1">
                    <label className="form-label">User Type<span className="form-required">*</span></label>
                   <Select className="zindex1"
                     value={selectedOptionUserType}
                      onChange={this.handleChangeUserType}
                      options={optionUserTypes}
                      defaultValue={selectedOptionUserType}
                    />
                   <label style={{ display: this.state.errors.userType ? 'block' : 'none' }} className="error">{this.state.errors.userType}</label>
                  </div>
                 
                  
                </Form.FieldSet>

                {!this.state.isLoading ?
                  <Button color="primary btn-block col-lg-4 aligncentre" type="submit">Create</Button>
                  :
                  <Buttonloader 
                      loading={this.state.isLoading}
                      addNewClass="col-lg-4 aligncentre"
                  ></Buttonloader>
                }
              </form>
              <ToastContainer autoClose={5000} position={'bottom-right'}/>
            </Card.Body>
          </Card>
              <Card>
                <Card.Header>
                <Card.Title>Upload Users Using Csv/Excel </Card.Title>
                </Card.Header>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.ColHeader className="text-tranform">
                        <Link className="backcolor" to="../../../demo/testfile.csv" download target="_blank">
                          Click To Download Demo Csv File
                        </Link>
                      </Table.ColHeader>
                    </Table.Row> 
                  </Table.Header>
              </Table>
                  <form id="csvForm" onSubmit={this.handleSubmit.bind(this)}>
                    <Form.FieldSet className="col-lg-12">
                      <Form.Group
                        
                        label="" className="upload-cont"
                      >
                      <div className="upload-file">
                        <img src={process.env.REACT_APP_publicUrl + '/images/upload.png'} />
                        <p>Drop csv file here to start uploading</p>

                        <span>or</span>
                        <button>{fileName}</button>

                      </div>
                        <Form.Input
                          name="example-text-input"
                          type="file"

                          onChange={(e) => this.onChangeFile(e)}
                        />
                      </Form.Group>
                      <Button color="primary btn-block " className="col-lg-4 aligncentre" type="submit">Import</Button>
                    </Form.FieldSet>
                  </form>
                
              </Card>
            </Grid.Col>
             <CsvErrorMesage 
              openPopup={this.state.openPopupCsv} 
              onChange={this.closePopup}
              ></CsvErrorMesage>
          </Grid.Row>
        </Page.Content>
       <ToastContainer autoClose={5000} position={'bottom-right'}/>
      </SiteWrapper >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));
